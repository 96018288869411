
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Form,
    FormField,
    Link,
    Modal,
    RadioGroup,
    SpaceBetween,
    Textarea
} from "@amzn/awsui-components-react";

import { useState } from "react";
import { NoVerifyReasonType } from "../../core/constants";
import { FlashbarPublisher } from "../FlashbarPublisher";
import sendReasons from "../../helpers/sendReasons";

export const ResolutionModal = ({ isVisible, setIsVisible, refreshBypasses, selectedItems }) => {
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState(undefined);
    const [reasonErrorText, setReasonErrorText] = useState("");
    const [details, setDetails] = useState("");
    const [detailsErrorText, setDetailsErrorText] = useState("");
    const [checked, setChecked] = useState(false);
    const [checkedErrorText, setCheckedErrorText] = useState("");
    const [modalError, setModalError] = useState(false);

    const resetFormAndHideModal = () => {
        setIsVisible(false);
        setReason(undefined);
        setDetails("");
        setChecked(false);
        setModalError(false);
    }

    const validateFormFields = (setErrors) => {
        let hasErrors = false;
        switch (reason) {
            case NoVerifyReasonType.Intentional:
                if (!details) {
                    if (setErrors) {
                        setDetailsErrorText("Please provide details for this bypass.");
                    }
                    hasErrors = true;
                }

                if (details.length > 250) {
                    if (setErrors) {
                        setDetailsErrorText("Character length must be less than 250.");
                    }
                    hasErrors = true;
                }
                if (details.trim().length === 0) {
                    if (setErrors) {
                        setDetailsErrorText("Must input a reason.")
                    }
                    hasErrors = true;
                }
                break;
            case NoVerifyReasonType.Unintentional:
                if (!checked) {
                    if (setErrors) {
                        setCheckedErrorText("Please acknowledge that you have read the above statement.");
                    }
                    hasErrors = true;
                }
                break;
            default:
                hasErrors = true;
                if (setErrors) {
                    setReasonErrorText("Plese select a reason.");
                }
                break;
        }
        return hasErrors;
    }

    const submitResolution = async () => {
        const hasErrors = validateFormFields(true);
        if (hasErrors) {
            return;
        }
        try {
            setLoading(true);
            await sendReasons(selectedItems, reason, details);
            resetFormAndHideModal();
            FlashbarPublisher.setItems([{
                id: "submit_reason_success",
                type: "success",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "Bypass acknowledged."
            }]);
            await refreshBypasses();
        } catch (e) {
            setModalError(true);
        }
        setLoading(false);
    }

    return (
        <Modal
            data-testid="acknowledge-bypass-modal"
            onDismiss={() => resetFormAndHideModal()}
            visible={isVisible}
            header="Submit reason"
            footer={<SpaceBetween direction="vertical" size="xs">
                {modalError && <Alert
                    statusIconAriaLabel="Error"
                    type="error"
                    header="There was an error submitting your bypass reason."
                >
                    Please try again later.
                </Alert>}
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            data-testid={"cancel-acknowledge-bypass-modal-button"}
                            variant="link"
                            onClick={() => resetFormAndHideModal()}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-testid={"submit-acknowledge-bypass-modal-button"}
                            variant="primary"
                            onClick={submitResolution}
                            loading={loading}
                            disabled={validateFormFields(false)}
                        >
                            Submit
                        </Button>
                    </SpaceBetween>
                </Box >
            </SpaceBetween>
            }
        >
            <form onSubmit={event => event.preventDefault()}>
                <Form>
                    <SpaceBetween size="l" direction="vertical">
                        <div>Provide a reason for the selected <strong>{selectedItems.length} bypass{selectedItems.length > 1 ? 'es' : ''}.</strong></div>
                        <FormField
                            data-testid={"reason-form-field"}
                            label={`Select reason`}
                            errorText={reasonErrorText}
                        >
                            <RadioGroup
                                onChange={({ detail }) => {
                                    setReason(detail.value);
                                    setReasonErrorText("");
                                }}
                                value={reason}
                                items={[
                                    {
                                        value: NoVerifyReasonType.Intentional,
                                        label: "I bypassed intentionally",
                                        description: "Unique situations where you can either use \"--no-verify\" flag intentionally, or configure the tool to intentionally bypass the scan."
                                    },
                                    {
                                        value: NoVerifyReasonType.Unintentional,
                                        label: "I bypassed unintentionally",
                                        description: "Something in my workflow triggered it. Potential reasons are using tool configurations and Code Defender limitations."
                                    }
                                ]}
                            />
                        </FormField>
                        {reason === NoVerifyReasonType.Unintentional && <Alert
                            statusIconAriaLabel="Warning"
                            type="warning"
                        >
                            This is extremely risky behavior as your commits may contain sensitive information like secret keys or access IDs.
                        </Alert>}
                        {reason === NoVerifyReasonType.Intentional && <FormField
                            data-testid={"details-form-field"}
                            label={`Details`}
                            errorText={detailsErrorText}
                        >
                            <Textarea
                                onChange={({ detail }) => {
                                    setDetails(detail.value);
                                    setDetailsErrorText("");
                                }}
                                invalid={details.length > 250}
                                value={details}
                                rows={5}
                                placeholder={`If you think it is a false positive, specify details to let your manager know what kind of secret was identified and why you believe this is a false positive.\n\nIf you have a unique situation, specify what the project is and why a bypass is needed.`}
                            />
                            {`${details.length}/250`}
                        </FormField>}
                        {reason === NoVerifyReasonType.Unintentional && <FormField
                            data-testid={"acknowledge-form-field"}
                            label={`Acknowledge`}
                            errorText={checkedErrorText}
                        >
                            <Checkbox
                                onChange={({ detail }) => {
                                    setChecked(detail.checked);
                                    setCheckedErrorText("");
                                }}
                                checked={checked}
                                description="If I don't resolve the issue and still trigger bypass alert in 30 days, it will be flagged as a potential compliance issue and escalate to my manager."
                            >
                                I acknowledge that I will follow the{" "}
                                <Link external>
                                    Bypass Troubleshooting Guide
                                </Link>
                                {" or "}
                                <Link external>
                                    submit a SIM ticket
                                </Link>
                                {" "}to the Code Defender team to resolve this issue.
                            </Checkbox>
                        </FormField>}
                        {reason === NoVerifyReasonType.Intentional && <Alert
                            statusIconAriaLabel="Info"
                            header="Do you need additional support?"
                            action={
                                <Button
                                    ariaLabel="Submit ticket (opens new tab)"
                                    href="https://example.com"
                                    iconAlign="right"
                                    iconName="external"
                                    target="_blank">
                                    Submit ticket
                                </Button>
                            }
                        >
                            Submit a SIM ticket to get further support or submit a feature request.
                        </Alert>}
                    </SpaceBetween>
                </Form>
            </form>
        </Modal >
    );
};