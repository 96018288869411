import {
  Alert,
  BreadcrumbGroup,
  Header,
  Link,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import getBypasses from "../../helpers/getBypasses";
import { FlashbarPublisher } from "../FlashbarPublisher";
import { UnacknowledgedBypassesTable } from "./UnacknowledgedBypassesTable";

export const UnacknowledgedBypasses = () => {
  const [newBypasses, setNewBypasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchBypasses = async () => {
    try {
      setLoading(true);
      const result = await getBypasses();
      setNewBypasses(result.filter((bypass) => !bypass.noVerifyReasonType));
    } catch (e) {
      console.error(e);
      FlashbarPublisher.setItems([
        {
          id: "fetch_bypasses_error",
          type: "error",
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: FlashbarPublisher.clear,
          content: "There was an error fetching your bypasses.",
        },
      ]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBypasses();
  }, []);

  return (
    <div data-testid={"unacknowledged-bypasses"}>
      <SpaceBetween direction="vertical" size="s">
        <BreadcrumbGroup
          test-id="breadcrumb-group"
          items={[
            { text: "Code Defender", href: "/" },
            {
              text: "My unacknowledged bypasses",
              href: "/bypasses/unacknowledged",
            },
          ]}
          ariaLabel="Breadcrumbs"
          onClick={(ev) => {
            ev.preventDefault();
            navigate(ev.detail.href);
          }}
        />

        <Header
          variant="h1"
          description={
            <>
              Shows bypasses from the last 90 days. Bypasses are commits made
              with the <code> --no-verify</code> flag, which skips Code
              Defender's security hooks.{" "}
              <Link external href="https://example.com/" variant="primary">
                Learn more about bypasses
              </Link>
            </>
          }
        >
          My unacknowledged bypasses
        </Header>

        <Alert
          statusIconAriaLabel="Warning"
          type="warning"
          header="[Action Required] Submit reasons for all unacknowledged bypasses."
        >
          If you don't submit a reason after 30 days, it will be flagged as a
          potential compliance issue and escalate to your manager.
        </Alert>

        <div data-testid={"unacknowledged-bypasses"}>
          <div data-testid={"unacknowledged-bypasses-table"}>
            <UnacknowledgedBypassesTable
              bypasses={newBypasses}
              loading={loading}
              refresh={fetchBypasses}
            />
          </div>
        </div>
      </SpaceBetween>
    </div>
  );
};
