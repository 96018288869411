import { Badge } from "@amzn/awsui-components-react";

// List of alert tags
export const AlertTags = {
  RightAfterBlockedCommit: "Right after blocked commit",
  MarkedAsIntentional: "Marked as intentional",
  RecurringUnintentional: "Recurring unintentional",
  MissingReasonForThirtyPlusDays: "Missing reason for 30+ days",
};

// Gets the properly styled badge component for each tag
export function getTagComponent(tag) {
  let text = "Unknown";
  let color = "severity-neutral";
  switch (tag) {
    case AlertTags.RightAfterBlockedCommit:
      text = AlertTags.RightAfterBlockedCommit;
      color = "severity-critical";
      break;
    case AlertTags.MarkedAsIntentional:
      text = AlertTags.MarkedAsIntentional;
      color = "severity-high";
      break;
    case AlertTags.RecurringUnintentional:
      text = AlertTags.RecurringUnintentional;
      color = "severity-medium";
      break;
    case AlertTags.MissingReasonForThirtyPlusDays:
      text = AlertTags.MissingReasonForThirtyPlusDays;
      color = "severity-low";
      break;
    default:
      return "-";
  }
  return <Badge color={color}>{text}</Badge>;
}

// Formats the list of tags for the select component of the AllBypassesTable
export function getSelectList() {
  return Object.keys(AlertTags).map((key) => ({
    value: AlertTags[key],
    label: getTagComponent(AlertTags[key]),
  }));
}

// Formats the text for the select component of the AllBypassesTable
export function getSelectText(selected) {
  if (selected.length === getSelectList().length) {
    return "All";
  }
  return `${selected.length} selected`;
}
